


  .book.coming-soon {
      opacity: .75;
      filter: grayscale(100%);
  }

  .book.coming-soon::before {
    content: "coming soon";
    display: block;
    position: absolute;
    bottom: 50%;
    text-align: center;
    width: 100%;
    opacity: 1;
    color: yellow;
    filter: none;
    transform: rotate(-30deg);
    font-size: 2vh;
    text-shadow: black 1px 1px;
  }

  #books {
    background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url("./img/bg-books.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }

  @media(min-width: 481px) {
    .book_shelf {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .book {
        background-color: gainsboro;
        margin: 1vh;
        text-align: center;
        position: relative;
      }
  }

  @media(max-width: 480px) {
    .book_shelf {
        display: block;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .book {
        margin: 1vh;
        text-align: center;
        position: relative;
      }

      #books {
          padding-bottom: 1em;;
      }
  }
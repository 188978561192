.scene {
    width: 20vh;
    height: 24vh;
    perspective: 80vh;
}

.cube {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-8vh) rotateY(-30deg)  translateX(3vh);
}

.cube__face {
    position: absolute;
    width: 16vh;
    height: 24vh;
}

.cube__face--front {
    transform: rotateY(0deg) translateZ(8vh);
}
.cube__face--right {
    width: 2.4vh;
    background-color: white;


    transform: rotateY(90deg) translateZ(14.85vh) translateX(-6.97vh);
}

.bookInFocus {
    /* border: 1px solid white; */
}
.bookInfo {
    /* border: 1px solid yellow; */
}
.scene {
    /* border: 1px solid red; */
}
.cube {
    /* border: 1px solid greenyellow; */
    /* display: none; */
}
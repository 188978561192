@font-face {
    font-family: "bedtime";
    src: url("./fonts/dolphins.woff") format("woff");
}
@font-face {
    font-family: "insomniac";
    src: url("./fonts/FontdinerSwanky.woff") format("woff");
}

.bedtime {
    font-family: bedtime;
    font-size: 60px;
    color: #FF0;
    line-height: 60px;
}

.insomniac {
    font-family: insomniac;
    font-size: 40px;
    color: #0F0;
    line-height: 70px;
}
.fiction {
    font-family: special_eliteregular, 'Courier New', Courier, monospace;
    color: #0FF;
}

.letme {
    font-family: special_eliteregular, 'Courier New', Courier, monospace;
    color: #FFF;
    font-weight: normal;
}

#podcast h3.letme {
    font-weight: normal;
    text-align: end;
}

#podcast {
  background: linear-gradient(
      to bottom,
      rgba(22, 22, 22, 0.1) 0%,
      rgba(22, 22, 22, 0.5) 75%,
      #161616 100%
    ),
    url("./img/bg-podcast.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

#podcast h2 {
    color: white;
    text-shadow: black -2px 2px ;
    font-family: special_eliteregular, 'Courier New', Courier, monospace;
}
#podcast h3 {
    font-weight: bold;
    font-style: italic;
}

.d-flex button {
    margin: .5em;

}

#podcast div button.btn-lg {
  font-size: 1.25rem;
  padding: .5em;
}

#spotify {
}

#apple {
    color: white;
    background-color: darkorchid;
}
#apple:hover {
    background-color: rgb(131, 42, 175);
}

#google {
    color: blue;
}

#google img, #pocketcasts img {
    vertical-align: text-bottom;
}
#podcast .card {
    border: none;
    background: none;
    width: 300px;
    margin-bottom: 2em;
}

#podcast .card h5 {
    position: absolute;
    height: calc(50px - .5em);
    top: 250px;
    text-align: center;
    background-color: rgba(255,255,255,.75);
    margin: .25em;
    width: calc(100% - .5em);
    line-height: calc(50px - .5em);
    border-radius: .25em;
    border: solid 1px black;
}

#podcast .card a.btn {
    margin-top: .25em;
    padding: 1em;
}

#podcast .description {
    background-color: rgba(0, 0, 0, .70);
    color: whitesmoke;
    padding: 8px;
    height: 200px;
    width: 100%;
}

#podcast .card img {
    box-sizing: border-box;
    border: 2px solid white;
    /* box-shadow: 0 -5px 5px white; */
}

@media (min-width: 481px) {
}

@media (max-width: 480px) {
}

#home .container {
    min-height: 800px;
    box-sizing: content-box;
}

.booksInFocus {
    display: flex;
    justify-content: space-around;
}

.bookInFocus {
    display: flex;
    justify-content: space-around;
}

.bookInfo {
    color: white;
    text-shadow: black 1px 1px 1px;
    width: 100%;
    text-align: center;
    
    background: linear-gradient(0deg, rgba(136,136,136,0), rgba(0,0,0,0.6) 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1em;
    border-radius: 2em 2em 0 0 ;
}

.bookInfoHeading {
    font-size: 1.5em;
}

.bookCoverImg {
    height: 25vh;
}

#podcast-logo {
    height: 200px;
    width: 200px;
    border-radius: 2em 0 0 0;
}

#episodes img {
    height: 100px;
    width: 100px;
    border: 1px solid white;
    box-shadow: 0 0 1em white;
    display: block;
    border-radius: 0;
}

#podcast-desc {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(192,192,192,0), rgba(255,255,255,0.3) 100%);
    padding: 1em;
    border-radius: 0 2em 0 0;
}

.masthead {
    padding: 5rem 0 15rem 0;
}

#podcast-preview a.btn-sm {
    padding: .25em;
    margin-right: 1em;
}

.episode-preview {
    flex-grow: 1;
    flex-basis: 100%;
    background-color: rgba(0,0,0,0.6);
    margin: 0 .25em;
    color: whitesmoke;
    border-radius: 1em 1em .5em 1em;
}

.episode-preview > div {
    position: relative;
}

.episode-preview a {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 1em);
    box-sizing: border-box;
}

.episode-preview h6 {
    margin: .25em;
    color: whitesmoke;
}

#subscribe {
    width: calc(100% - .5em);
}

#home h3 {
    font-size: 3em;
    text-shadow: -2px 2px 3px whitesmoke;
    font-weight: bold;
    font-style: italic;
}

@media(min-width: 481px) {
    .this-is-rich {
        height: 25vmin;
        position: absolute;
        bottom:0;
        right: 0;
      }
      .signature {
        height: 13vmin;
      }
      .floating-head  {
        height: 25vmin;
        margin: 0 3vw;
      }
      .cta {
          display: flex;
          justify-content: center;
      }
      .cta > div {
        margin: 0 .5em ;
      }
      
}

@media(max-width: 480px) {
.masthead h1 {
    font-size: calc(9vw)
}

    .bookCoverImg {
        height: calc(40vw * 9 / 6)
    }

    .bookInFocus {
        margin-bottom: 1em;
    }
    .this-is-rich {
        width: 100vw;
        position: absolute;
        bottom:0;
        right: 0;
      }
      .signature {
        width: 75vw;
      }
      .floating-head  {
        width: 20vw;
        margin-right: 5vw;
      }
            .cta {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
      .cta > div:first {
        margin: 0 0 .5em 0 ;
      }
      #home h3 {
        font-size: 2em;
      }
      #subscribe {
        width: 100%;
    }
    #episodes > div {
        margin-bottom: 1rem;
    }
}
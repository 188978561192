div.col-3.reviewList {
    height: calc(85vh - 200px);
    padding-right: 0;
    overflow-y: auto;
}
.reviewDetail {
    height: calc(85vh - 200px);
    background-color: rgba(255, 255, 255, .85);
    box-sizing: border-box;
    overflow-y: auto;
}

.reviewDetail p {
    padding: 1em;
    font-size: 1.2em;
}

.reviewLabel {
    margin-top: 1em;
    background-color: rgba(192, 192, 192, .75);
}

.reviewLabel.selected {
    background-color: rgba(255, 255, 255, .85);
}

  #reviews {
    background: linear-gradient(to bottom, rgba(22, 22, 22, 0.1) 0%, rgba(22, 22, 22, 0.5) 75%, #161616 100%), url("./img/bg-reviews.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }

  .fiveStarBadge {
      float: right;
      margin: 0 0 1em 1em;
  }

  @media(min-width:769px) {
      .mobile-override button {
          display: none;
      }
  }

  @media(max-width: 768px) {
      .mobile-override {
          position: absolute;
          background-color: rgb(255, 255, 255);
      }

      .fiveStarBadge {
          width: 25vw;
      }

      .mobile-override.mobile-hide {
          display: none;
      }
      .reviewDetail {
          max-width: 540px;
          width: 100%;
      }
  }
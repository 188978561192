#contact div.card {
    background-color: whitesmoke;
}

#contact .card-body {
    padding-bottom: 1.25rem;
}

.link {
    border-radius: .5em;
    padding: .25em;
}
#podcast {
  background: linear-gradient(
      to bottom,
      rgba(22, 22, 22, 0.1) 0%,
      rgba(22, 22, 22, 0.5) 75%,
      #161616 100%
    ),
    url("./img/bg-podcast.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

#podcast h2 {
    color: white;
    text-shadow: black -2px 2px ;
    font-family: special_eliteregular, 'Courier New', Courier, monospace;
}

.d-flex button {
    margin: .5em;

}

#podcast div button.btn-lg {
  font-size: 1.25rem;
}

#spotify {
}

#apple {
    color: white;
    background-color: darkorchid;
}
#apple:hover {
    background-color: rgb(131, 42, 175);
}

#google {
    color: blue;
}

#google img, #pocketcasts img {
    vertical-align: text-bottom;
}

.card-body {
    padding-bottom: 60px;
}

.card-body p {
    font-size: .75em;
}
.card-body a.btn {
    position: absolute;
    bottom: 0;
    left: 0;
    margin:.5em;
    width: calc(100% - 1em);
}

@media (min-width: 481px) {
}

@media (max-width: 480px) {
}

@media (min-width: 992px){
    #mainNav .nav-link {
        padding: 1em 1.5em;
    }
    #mainNav {
        background-color: #161616;
    }
}

.nav-item, .navbar-brand {
    cursor: pointer;
}